import firebase from 'firebase'

const state = {
    isUserLogged: null,
    users: [],
    reports: [],
    connects: [],
    isUserLoggedOut: null,
}

const getters = {
    getUserLogged: state => state.isUserLogged,
    getUsers: state => state.users,
    getUserLoggedOut: state => state.isUserLoggedOut,
    getReports: state => state.reports,
    getConnects: state => state.connects
}

const actions = {
    // Login User
   async userLogin ({ commit }, payload) {
    commit('setUserLogged', null)
    await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      .then(() => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            firebase.database().ref('users').child(user.uid)
              .once('value', (snapshot) => {
                if (snapshot.val()) {
                  if (snapshot.val().role === 'admin') {
                    commit('setLoggedUser', {
                      ...snapshot.val(),
                      id: snapshot.key
                    })
                    commit('setUserLogged', 1)
                    commit('setToast', { message: 'Logged in Successfully.', type: 'green', show: true })
                    return 1
                  } else {
                    commit('setToast', { message: 'Only Admin access this system.', type: 'red', show: true })
                    commit('setUserLogged', 0)
                    return 0
                  }
                } else {
                  commit('setToast', { message: 'Record Not Found', type: 'red', show: true })
                  commit('setUserLogged', 0)
                  return 0
                }
              })
          }
        })
      })
      .catch((error) => {
        commit('setUserLogged', 0)
        commit('setToast', { message: error.message, show: true, type: 'red' })
      })
  },

  // LOGOUT USER
  signOutRequest (context) {
    context.commit('setUserLogout', null)
    firebase.auth().signOut()
      .then(() => {
        context.commit('setUserLogout', 1)
      }).catch(() => {
        context.commit('setUserLogout', 1)
      })
  },

  // Fetch Users
  fetchUsers({commit}) {
    firebase.database().ref('users').on('value', snapshot => {
      commit('setUsers', snapshot.val())
    })
  },

  // Update User
  updateUser({commit}, payload) {
    firebase.database().ref('users').child(payload.id).update(payload).then(() => {
        commit('setToast', { message: 'User Updated Successfully.', type: 'green', show: true })
    })
  },

  // Delete User
  deleteUser({commit}, payload) {
    firebase.database().ref('users').child(payload.id).update({isDeleted: true}).then(() => {
        commit('setToast', { message: 'User Deleted Successfully.', type: 'green', show: true })
    })
  },

  // Fetch Reports
  fetchReports({commit}) {
      firebase.database().ref('reports').on('value', snapshot => {
          commit('setReports', snapshot.val())
      })
  },

  // Fetch Connects
  fetchConnects({commit}) {
    firebase.database().ref('connects').on('value', snapshot => {
        commit('setConnects', snapshot.val())
    })
  }
}

const mutations = {
    setLoggedUser: (state, payload) => {
        const loggedUser = localStorage.getItem('postAdmin')
        if (loggedUser) {
        localStorage.removeItem('postAdmin')
        }
        localStorage.setItem('postAdmin', JSON.stringify(payload))
        
      },
      setUserLogout: (state, payload) => {
        if (payload === 1) {
            const loggedUser = localStorage.getItem('postAdmin')
            if (loggedUser) {
                localStorage.removeItem('postAdmin')
                state.isUserLoggedOut = payload
            }
        }
      },
      setUserLogged: (state, payload) => {
        state.isUserLogged = payload
      },

    setUsers: (state, payload) => {
        const userArray = []
        for (const user in payload) {
            payload[user].id = user
            userArray.push(payload[user])
        }

        state.users = userArray
    },

    setConnects: (state, payload) => {
        const connectArray = []
        for (const connect in payload) {
            payload[connect].id = connect
            connectArray.push(payload[connect])
        }

        state.connects = connectArray
    },

    setReports: (state, payload) => {
        const reportArray = []
        for (const report in payload) {
            payload[report].id = report
            reportArray.push(payload[report])
        }

        state.reports = reportArray
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}