import firebase from 'firebase'

const state = {
    posts: []
}

const getters = {
    getPosts: (state) => state.posts
}

const actions = {
    // Fetch Posts
    fetchPosts({commit}) {
        firebase.database().ref('posts').on('value', snapshot => {
            commit('setPosts', snapshot.val())
        })
    },

    // Update Post
    updatePost({commit}, payload) {
        firebase.database().ref('posts').child(payload.id).update(payload).then(() => {
            commit('setToast', { message: 'Post Status Updated Successfully.', type: 'green', show: true })
        })
    },

    // Delete Post
    deletePost({commit}, payload) {
        firebase.database().ref('posts').child(payload.id).remove().then(() => {
            commit('setToast', { message: 'Post Deleted Successfully.', type: 'green', show: true })
        })
    }
}

const mutations = {
    setPosts: (state, payload) => {
        const postArray = []
        for (const post in payload) {
            payload[post].id = post
            postArray.push(payload[post])
        }

        state.posts = postArray
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}