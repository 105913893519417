<template>
    <v-container
        id="posts"
        fluid
        tag="section"
    >
        <h3 class="text-uppercase">All Trades</h3>
        <v-row class="mt-4">
            <v-col cols="12" md="4">
                <v-text-field outlined v-model="search" color="orange" label="SEARCH TRADES"></v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="loading">
            <v-col cols="12" class="text-center">
                <v-progress-circular
                    indeterminate
                    color="orange"
                ></v-progress-circular>
            </v-col>
        </v-row>
        <v-data-table
            v-if="!loading"
            :headers="headers"
            :items="posts"
            :search="search"
            class="elevation-1 mt-4"
        >
            <template v-slot:item.status="{ item }">
                <v-chip :color="item.status === 'Active' ? 'green' : 'red'" @click="update(item)" dark>{{item.status}}</v-chip>
            </template>
            <template v-slot:item.date="{ item }">
                {{getDate(item.date).date}}
            </template>
            <template v-slot:item.traderId="{ item }">
                <v-chip color="orange" @click="traderModal = !traderModal" dark>Trader{{item.traderId}}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn small color="info"  class="mr-2 mt-2 mb-2" depressed @click="view(item)" :disabled="item.status !== 'Active'"><v-icon class="pr-1">mdi-eye</v-icon> View</v-btn>
                <v-btn small color="red" class="ml-2 mt-2 mb-2" depressed @click="remove(item)"><v-icon>mdi-delete</v-icon> Delete</v-btn>
            </template>
                    
        </v-data-table>

        <v-dialog persistent max-width="500px" v-model="deleteModal">
            <v-card v-if="postToDelete">
                <v-card-text class="pt-3">
                    <div class="d-flex justify-space-between">
                        <h4>Delete Post</h4>
                        <v-icon @click="deleteModal = !deleteModal">mdi-close</v-icon>
                    </div>
                    <v-divider class="my-3 grey-lighten-3"></v-divider>
                    <h3 class="text-center">Are you sure you want to delete post ?</h3>

                    <div class="text-center mt-3">
                        <v-btn color="green" depressed @click="deletePost(postToDelete)">Yes, Delete</v-btn>
                        <v-btn color="red" class="ml-3" depressed @click="deleteModal = !deleteModal">No, Cancel</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent max-width="500px" v-model="updateModal">
            <v-card v-if="post">
                <v-card-text class="pt-3">
                    <div class="d-flex justify-space-between">
                        <h4>{{post.status === 'Active' ? 'Close' : 'Active'}} Post</h4>
                        <v-icon @click="updateModal = !updateModal">mdi-close</v-icon>
                    </div>
                    <v-divider class="my-3 grey-lighten-3"></v-divider>
                    <h3 class="text-center">Are you sure you want to {{post.status === 'Active' ? 'Close' : 'Active'}} post ?</h3>

                    <div class="text-center mt-3">
                        <v-btn color="green" depressed @click="updateStatus(post)">Yes, {{post.status === 'Active' ? 'Close' : 'Active'}}</v-btn>
                        <v-btn color="red" class="ml-3" depressed @click="updateModal = !updateModal">No, Cancel</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent max-width="500px" v-model="traderModal">
            <v-card>
                <v-card-text class="pt-3">
                    <div class="d-flex justify-space-between">
                        <h4>Trader Profile</h4>
                        <v-icon @click="traderModal = !traderModal">mdi-close</v-icon>
                    </div>
                    <v-divider class="my-3 grey-lighten-3"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <p><strong class="orange--text">Name</strong> <br> <span>Trader</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Email</strong> <br> <span>trader@gmail.com</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Status</strong> <br> <span>Active</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Verified</strong> <br> <span>Verified</span></p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog max-width="500px" v-model="viewModal">
            <v-card v-if="post">
                <v-card-text class="pt-3">
                    <div class="d-flex justify-space-between">
                        <h4>Post Details <v-chip class="ml-3" small :color="post.status === 'Active' ? 'orange' : 'red'" dark>{{post.status}}</v-chip></h4>
                        <v-icon @click="viewModal = !viewModal">mdi-close</v-icon>
                    </div>
                    <v-divider class="my-3 grey-lighten-3"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <p><strong class="orange--text">Action</strong> <br> <span>{{post.action}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Commodity</strong> <br> <span>{{post.commodity}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Price</strong> <br> <span>{{post.price}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Delivery</strong> <br> <span>{{post.delivery}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Destination</strong> <br> <span>{{post.destination}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Origin</strong> <br> <span>{{post.origin}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Payment Term</strong> <br> <span>{{post.payment_term}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Date</strong> <br> <span>{{getDate(post.date).date}}</span></p>
                        </v-col>
                        <v-col cols="12">
                            <p><strong class="orange--text">Description</strong> <br> <span>{{post.description}}</span></p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "Posts",
    data() {
        return {
            search: null,
            postToDelete: null,
            deleteModal: false,
            viewModal: false,
            traderModal: false,
            updateModal: false,
            post: null,
            loading: false,
            headers: [
                {
                    text: "Action",
                    value: "action",
                    sortable: true
                },
                {
                    text: "Commodity",
                    value: "commodity",
                    sortable: true
                },
                {
                    text: "Price",
                    value: "price",
                    sortable: true
                },
                {
                    text: "Payment Term",
                    value: "payment_term",
                    sortable: true
                },
                {
                    text: "Delivery",
                    value: "delivery",
                    sortable: true
                },
                {
                    text: "Destination",
                    value: "destination",
                    sortable: true
                },
                {
                    text: "Origin",
                    value: "origin",
                    sortable: true
                },
                {
                    text: "Status",
                    value: "status",
                    sortable: true
                },
                {
                    text: "Trader ID",
                    value: "traderId",
                    sortable: true
                },
                {
                    text: "Date",
                    value: "date",
                    sortable: true
                },
                {
                    text: "Actions",
                    value: "actions"
                }
            ],

            posts: []
        }
    },
    computed: {
        ...mapGetters(['getUsers', 'getPosts'])
    },
    created() {
        if(!this.getUsers.length) {
            this.$store.dispatch('fetchUsers')
        }
        if(!this.getPosts.length) {
            this.loading = true
            this.$store.dispatch('fetchPosts')
        }
        else {
            this.posts = this.getPosts
        }
    },
    watch: {
        getPosts(val) {
            if(val && val.length) {
                this.loading = false
                this.posts = val
            }
        }
    },
    methods: {
        remove(post) {
            this.postToDelete = Object.assign({}, post)
            this.deleteModal = true
        },
        deletePost(post) {
            this.$store.dispatch('deletePost', post)
            this.deleteModal = false
        },
        view(post) {
            this.post = Object.assign({}, post)
            this.viewModal = true
        },
        update(post) {
            this.post = Object.assign({}, post)
            this.updateModal = true
        },
        updateStatus(post) {
            let status = null
          if(post.status === 'Active') {
              status = 'Closed'
          }
          else {
              status = 'Active'
          }
          post.status = status
          this.$store.dispatch('updatePost', post)
          this.updateModal = false
        }
    }
}
</script>