<template>
  <div class="home">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="4" lg="3">
          <v-card class="py-5" @click="$router.push('/users')">
            <v-card-title class="d-flex justify-space-between">
              <h2 class="orange--text" style="font-weight:400">Users</h2>
              <h1 class="orange--text">{{users}}</h1>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="3">
          <v-card class="py-5" @click="$router.push('/posts')">
            <v-card-title class="d-flex justify-space-between">
              <h2 class="orange--text" style="font-weight:400">Trades</h2>
              <h1 class="orange--text">{{posts}}</h1>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="3">
          <v-card class="py-5" @click="$router.push('/reports')">
            <v-card-title class="d-flex justify-space-between">
              <h2 class="orange--text" style="font-weight:400">Reports</h2>
              <h1 class="orange--text">{{reports}}</h1>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="3">
          <v-card class="py-5" @click="$router.push('/connects')">
            <v-card-title class="d-flex justify-space-between">
              <h2 class="orange--text" style="font-weight:400">Connects</h2>
              <h1 class="orange--text">{{connects}}</h1>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  computed: {
    ...mapGetters(['getUsers', 'getPosts', 'getConnects', 'getReports']),
    posts() {
      if(this.getPosts) {
        return this.getPosts.length
      }
      return 0
      
    },
    users() {
      if(this.getUsers && this.getUsers.length) {
        const users = this.getUsers.filter(user => user.role !== 'admin' && user.isDeleted === false)
        return users.length
      }
      return 0      
    },
    reports() {
      if(this.getReports) {
        return this.getReports.length
      }
      return 0     
    },
    connects() {
      if(this.getConnects) {
        return this.getConnects.length
      }
      return 0
    }
  },
  created() {
    if(!this.getUsers.length) {
      this.$store.dispatch('fetchUsers')
    }
    if(!this.getPosts.length) {
      this.$store.dispatch('fetchPosts')
    }
    if(!this.getReports.length) {
      this.$store.dispatch('fetchReports')
    }
    if(!this.getConnects.length) {
      this.$store.dispatch('fetchConnects')
    }
  }
}
</script>
