<template>
    <v-container
        id="users"
        fluid
        tag="section"
    >
        <v-row justify="center">
            <v-col
                cols="12"
            >
                <h3>All Reports</h3>
                <!-- <v-row class="mt-4">
                    <v-col cols="12" md="4">
                        <v-text-field outlined v-model="search" color="orange" label="Search Reports"></v-text-field>
                    </v-col>
                </v-row> -->
                <v-row v-if="loading">
                    <v-col cols="12" class="text-center">
                        <v-progress-circular
                            indeterminate
                            color="orange"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
                <v-data-table
                    v-if="!loading"
                    :headers="headers"
                    :items="reports"
                    class="elevation-1 mt-4"
                >
                    <template v-slot:item.reported_by="{ item }">
                        <v-chip @click="userDetails(item.reported_by)" color="info" >{{getUserEmail(item.reported_by)}}</v-chip>
                    </template>
                    <template v-slot:item.reported_to="{ item }">
                        <v-chip @click="userDetails(item.reported_to)" color="red">{{getUserEmail(item.reported_to)}}</v-chip>
                    </template>
                    <template v-slot:item.post_id="{ item }">
                        <v-chip @click="postDetails(item.post_id)" color="orange">{{getPost(item.post_id)}}</v-chip>
                    </template>
                    <template v-slot:item.date="{ item }">
                        {{getDate(item.date).date}}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog max-width="500px" v-model="postModal">
            <v-card v-if="post">
                <v-card-text class="pt-3">
                    <div class="d-flex justify-space-between">
                        <h4>Post Details <v-chip class="ml-3" small :color="post.status === 'Active' ? 'green' : 'red'" dark>{{post.status}}</v-chip></h4>
                        <v-icon @click="postModal = !postModal">mdi-close</v-icon>
                    </div>
                    <v-divider class="my-3 grey-lighten-3"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <p><strong class="orange--text">Action</strong> <br> <span>{{post.action}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Commodity</strong> <br> <span>{{post.commodity}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Price</strong> <br> <span>{{post.price}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Delivery</strong> <br> <span>{{post.delivery}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Destination</strong> <br> <span>{{post.destination}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Origin</strong> <br> <span>{{post.origin}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Payment Term</strong> <br> <span>{{post.payment_term}}</span></p>
                        </v-col>
                        <v-col cols="6">
                            <p><strong class="orange--text">Date</strong> <br> <span>{{getDate(post.date).date}}</span></p>
                        </v-col>
                        <v-col cols="12">
                            <p><strong class="orange--text">Description</strong> <br> <span>{{post.description}}</span></p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent max-width="500px" v-model="userModal">
          <v-card>
              <v-card-text class="pt-3">
                  <div class="d-flex justify-space-between">
                      <h4>Profile</h4>
                      <v-icon @click="userModal = !userModal">mdi-close</v-icon>
                  </div>
                  <v-divider class="my-3 grey-lighten-3"></v-divider>
                  <v-row no-gutters v-if="user">
                      <v-col cols="6">
                          <p><strong class="orange--text">Name</strong> <br> <span>{{user.name}}</span></p>
                      </v-col>
                      <v-col cols="6">
                          <p><strong class="orange--text">Email</strong> <br> <span>{{user.email}}</span></p>
                      </v-col>
                      <v-col cols="6">
                          <p><strong class="orange--text">Status</strong> <br> <span>{{user.status ? 'Active' : 'Blocked'}}</span></p>
                      </v-col>
                      <v-col cols="6">
                          <p><strong class="orange--text">Verified</strong> <br> <span>{{user.verified ? 'Verified' : 'Not Verified'}}</span></p>
                      </v-col>
                  </v-row>
              </v-card-text>
          </v-card>
      </v-dialog>
        
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "Users",
    data() {
        return {
            loading: false,
            post: null,
            user: null,
            search: null,
            postModal: false,
            userModal: false,
            headers: [
                {
                    text: "Reported By",
                    value: "reported_by",
                    sortable: true
                },
                {
                    text: "User Reported",
                    value: "reported_to",
                    sortable: true
                },
                {
                    text: "Post Reported",
                    value: "post_id",
                    sortable: true
                },
                {
                    text: "Date",
                    value: "date",
                    sortable: true
                },
            ],
            reports: [],
        }
    },
    computed: {
        ...mapGetters(['getReports', 'getUsers', 'getPosts'])
    },
    created() {
        if(!this.getUsers.length) {
            this.$store.dispatch('fetchUsers')
        }
        if(!this.getPosts.length) {
            this.$store.dispatch('fetchPosts')
        }
        if(!this.getReports.length) {
            this.loading = true
            this.$store.dispatch('fetchReports')
        }
        else {
            this.reports = this.getReports
        }
    },
    watch: {
        getReports(val) {
            if(val && val.length) {
                this.loading = false
                this.reports = val
            }
        }
    },
    methods: {
        getUserEmail(id) {
            if(this.getUsers.length) {
                const user = this.getUsers.find(u => u.id === id)
                if(user) {
                    return user.email
                }
            }
        },
        getPost(id) {
            if(this.getPosts.length) {
                const post = this.getPosts.find(p => p.id === id)
                if(post) {
                    return post.commodity
                }
                return "Post Not Available"
            }
        },
        postDetails(id) {
            if(this.getPosts.length) {
                const post = this.getPosts.find(p => p.id === id)
                if(post) {
                    this.post = post
                    this.postModal = true
                }
            }
        },
        userDetails(id) {
            if(this.getUsers.length) {
                const user = this.getUsers.find(u => u.id === id)
                if(user) {
                    this.user = user
                    this.userModal = true
                }
            }
        }
    }
    
}
</script>
