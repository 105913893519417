<template>
    <footer style="background-color: #1E1E1E" class="py-4">
        <h1 class="text-center footer" style="font-size: 17px; font-family: Roboto; font-weight: 400">&copy; 2021 <a href="https://www.stankeviciusinternational.com/" style="text-decoration:none; color: #FF9800;">Stankevicius International</a> Limited. All right reserved.</h1>
        <h6  class="text-center" style="font-size: 12px; font-family: Roboto; font-weight: 400">Stankevicius International is a member of <a href="http://www.stankeviciusgroup.com" style=" text-decoration:none; color: #FF9800; ">Stankevicius Group</a></h6> 
    </footer>
</template>


<script>
export default {
    
}
</script>

