<template>
    <v-container
        id="users"
        fluid
        tag="section"
    >
        <v-row justify="center">
            <v-col
                cols="12"
            >
                <h3>All Users</h3>
                <v-row class="mt-4">
                    <v-col cols="12" md="4">
                        <v-text-field outlined v-model="search" color="orange" label="Search Users By Name, Email, or Role"></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="loading">
                    <v-col cols="12" class="text-center">
                        <v-progress-circular
                            indeterminate
                            color="orange"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
                <v-data-table
                    v-if="!loading"
                    :headers="headers"
                    :items="users"
                    :search="search"
                    class="elevation-1 mt-4"
                >
                    <template v-slot:item.status="{ item }">
                        <v-switch
                            v-model="item.status"
                            color="orange"
                            @change="updateStatus(item)"
                            hide-details
                        ></v-switch>
                    </template>
                    <template v-slot:item.verified="{ item }">
                        <v-chip @click="verifyUser(item)" :color="item.verified ? 'green' : 'gray'" dark>
                            {{item.verified ? 'Verfied' : 'Not Verfied'}}
                        </v-chip>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-btn small color="red" depressed @click="remove(item)"><v-icon>mdi-delete</v-icon> Delete</v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog persistent max-width="500px" v-model="verifyModal">
            <v-card v-if="userToVerify">
                <v-card-text class="pt-3">
                    <div class="d-flex justify-space-between">
                        <h4>Verify User</h4>
                        <v-icon @click="verifyModal = !verifyModal">mdi-close</v-icon>
                    </div>
                    <v-divider class="my-3 grey lighten-3"></v-divider>
                    <h3 class="text-center">Are you sure you want to verify <span class="green--text">{{userToVerify.name}}</span> ?</h3>

                    <div class="text-center mt-3">
                        <v-btn color="green" depressed @click="verify(userToVerify)">Yes, Verify</v-btn>
                        <v-btn color="red" class="ml-3" depressed @click="verifyModal = !verifyModal">No, Cancel</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent max-width="500px" v-model="notverifyModal">
            <v-card v-if="userToVerify">
                <v-card-text class="pt-3">
                    <div class="d-flex justify-space-between">
                        <h4>Unverify User</h4>
                        <v-icon @click="notverifyModal = !notverifyModal">mdi-close</v-icon>
                    </div>
                    <v-divider class="my-3 grey lighten-3"></v-divider>
                    <h3 class="text-center">Are you sure you want to unverify <span class="green--text">{{userToVerify.name}}</span> ?</h3>

                    <div class="text-center mt-3">
                        <v-btn color="green" depressed @click="unverify(userToVerify)">Yes, Unverify</v-btn>
                        <v-btn color="red" class="ml-3" depressed @click="notverifyModal = !notverifyModal">No, Cancel</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent max-width="500px" v-model="deleteModal">
            <v-card v-if="userToDelete">
                <v-card-text class="pt-3">
                    <div class="d-flex justify-space-between">
                        <h4>Delete User</h4>
                        <v-icon @click="deleteModal = !deleteModal">mdi-close</v-icon>
                    </div>
                    <v-divider class="my-3 grey-lighten-3"></v-divider>
                    <h3 class="text-center">Are you sure you want to delete <span class="green--text">{{userToDelete.name}}</span> ?</h3>

                    <div class="text-center mt-3">
                        <v-btn color="green" depressed @click="deleteUser(userToDelete)">Yes, Delete</v-btn>
                        <v-btn color="red" class="ml-3" depressed @click="deleteModal = !deleteModal">No, Cancel</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "Users",
    data() {
        return {
            loading: false,
            verifyModal: false,
            notverifyModal: false,
            userToVerify: null,
            deleteModal: false,
            userToDelete: null,
            search: null,
            headers: [
                {
                    text: "Name",
                    value: "name",
                    sortable: true
                },
                {
                    text: "Email",
                    value: "email",
                    sortable: true
                },
                {
                    text: "Role",
                    value: "role",
                    sortable: true
                },
                {
                    text: "Status",
                    value: "status",
                    sortable: true
                },
                {
                    text: "Verfied",
                    value: "verified",
                    sortable: true
                },
                {
                    text: "Action",
                    value: "action"
                }
            ],
            users: [],
        }
    },
    computed: {
        ...mapGetters(['getUsers'])
    },
    created() {
        if(!this.getUsers.length) {
            this.loading = true
            this.$store.dispatch('fetchUsers')
        }
        else {
            this.users = this.getUsers.filter(user => user.role !== 'admin' && user.isDeleted === false)
        }
    },
    watch: {
        getUsers(val) {
        if(val && val.length) {
            this.loading = false
            this.users = val.filter(user => user.role !== 'admin' && user.isDeleted === false)
        }
      }
    },
    methods: {
        verifyUser(user) {
            if(!user.verified) {
                this.userToVerify = Object.assign({}, user)
                this.verifyModal = true
            }
            else{
                this.userToVerify = Object.assign({}, user)
                this.notverifyModal = true
            }
        },
        verify(user) {
            user.verified = true
            this.$store.dispatch('updateUser', user)
            this.verifyModal = false
            
        },
        unverify(user) {
            user.verified = false
            this.$store.dispatch('updateUser', user)
            this.notverifyModal = false
            
        },
        remove(user) {
            this.userToDelete = Object.assign({}, user)
            this.deleteModal = true
        },
        deleteUser(user) {
            this.$store.dispatch('deleteUser', user)
            this.deleteModal = false
        },
        updateStatus(user) {
            this.$store.dispatch('updateUser', user)
        }
    }
}
</script>
