import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'
import firebase from 'firebase'

Vue.config.productionTip = false

var firebaseConfig = {
  apiKey: "AIzaSyDyjeQ5NQkawHMLm9P01jKXlt3Dv-A2Y5s",
  authDomain: "bt2021-82164.firebaseapp.com",
  databaseURL: "https://bt2021-82164-default-rtdb.firebaseio.com",
  projectId: "bt2021-82164",
  storageBucket: "bt2021-82164.appspot.com",
  messagingSenderId: "712830066643",
  appId: "1:712830066643:web:8b2ce80a409de0acc3d926",
  measurementId: "G-L3J9B2M9B2"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
window.firebase = firebase

import globalMixin from '../src/mixins/global'
Vue.mixin(globalMixin)

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const loggedUser = localStorage.getItem('postAdmin')

  if (requiresAuth && !loggedUser) {
    next('/login');
  }
  else {
    next();
    window.scrollTo(0,0)
  }
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
