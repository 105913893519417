<template>
    <div class="login">
    <v-app-bar v-if="$vuetify.breakpoint.mdAndDown">
      <v-app-bar-title>
        <img class="pt-1" src="../assets/img/stank.png" width="150" alt="">
      </v-app-bar-title>
    </v-app-bar>
    <v-row no-gutters>
      <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp" class="left-image"></v-col>
      <v-col cols="12" md="6" class="d-flex align-content-center">
        <div v-if="$vuetify.breakpoint.lgAndUp" class="white--text">
            <v-card class="info-area" flat>
              <img src="../assets/img/stank.png" width="60%" alt="">
              <p class="text-info">This login portal is a Stankevicius authentication service that allows you access to Business Terminal services from wherever you are.</p>
            </v-card>
        </div>
        <div>
          <v-card class="black login-area" flat>
            <v-card-text class="px-10">
              <h1 class="white--text py-12">Log In</h1>
              <form ref="form" @submit.prevent>
                <label for="" class="white--text">Business Terminal Email</label>
                <input type="email" id="email" v-model="user.email" required>
                <label for="" class="white--text pt-10">Password</label>
                <input type="password" id="password" v-model="user.password" required>

                <button class="login-btn" :disabled="loading" @click="login">Login</button>
                <!-- <p class="mt-2">
                    <span>Don't have an account ? <strong class="register" @click="register">Register</strong></span>
                </p> -->
              </form>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp" class="right-image"></v-col>
    </v-row>
  </div>
    <!-- <div class="login">

        <v-container
            tag="section"
        >
            <v-row justify="center" align="center">
                <v-col cols="12" md="10" lg="6" class="mt-10">
                    <v-card>
                        <v-card-text>
                            <h1 class="pb-5 text-center text-uppercase">Admin Login</h1>
                            <v-form ref="form" @submit.prevent>
                                <v-text-field type="email" :rules="emailRules" v-model="user.email" label="Email" outlined color="orange"></v-text-field>
                                <v-text-field label="password" :rules="passwordRules" color="orange" type="password" v-model="user.password" outlined></v-text-field>
                                <v-btn depressed color="orange" block @click="login" :disabled="loading" :loading="loading">Login</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div> -->
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "Login",
    computed: {
        ...mapGetters(['getUserLogged']),
    },
    watch: {
        getUserLogged (val) {
        if (val) {
            this.loading = false
            if (val === 1) {
                this.$refs.form.reset()
                this.$router.push('/')
            }
        }
        }
    },
    methods: {
        async login () {
            if (!this.$refs.form.validate()) {
                return
            }
            this.loading = true
            await this.$store.dispatch('userLogin', this.user)
            this.loading = false
        }
    },
    created () {
        if (this.getLoggedUser()) {
            this.$router.push('/')
        }
    },
    data() {
        return {
            loading: false,
            user: {
                email: null,
                password: null
            },
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            passwordRules: [
                v => !!v || 'Password is required'
            ]
        }
    }
}
</script>

<style scoped>
/* .login {
    min-height: 100vh;
} */

@font-face {
  font-family: 'AvenirNextPForBBG-Demi', 'AvenirNextPForBBG-Regular';
  src: url('../assets/fonts/AvenirNextPForBBG-Demi.svg')format("svg");
  src: url('../assets/fonts/AvenirNextPForBBG-Regular.svg')format("svg");;
}
.login-page {
  height: 100vh;
  width: 100%;
  max-height: 100vh;
  background: #141414;
  overflow: hidden !important;
  font-family: 'Helvetica' !important;
}
.register:hover {
    cursor: pointer
}
.left-image {
  background-image: url('../assets/img/bg-left.png');
  background-color: #141414cb;
  background-blend-mode: overlay;
  height: 100vh;
  width: 20%;
  background-repeat: repeat;
  background-position: 0 -13px;
  left: 0;
}
.right-image {
  background-image: url('../assets/img/bg-right.png');
  background-color: #141414cb;
  background-blend-mode: overlay;
  height: 100vh;
  width: 25%;
  background-repeat: repeat;
  background-position: 0 -13px;
  right: 0;
}
.info-area {
  width: 500px !important;
  left: -80px !important;
  border: none !important;
  background-color: #141414 !important;
  color: white !important;
  padding: 50px 10px 50px 50px;
  top: 30% !important;
  border: 1px solid red;
  
}
.login-area {
  top: 20% !important;
  min-width: 500px;
  right: 0px !important;
  overflow: visible !important;
  color:white;
  padding: 30px 0px;
}
h1 {
  font-size: 40px;
  margin-bottom: 30px;
}
input[type="email"], input[type="password"] {
  background-color: white;
  width: 100%;
  height:40px;
  margin-top:8px;
  padding: 0 8px;
  margin-bottom: 10px;
}

input[type="email"]:focus, input[type="password"]:focus {
  box-shadow: 0px 1px 25px #0D9DDB;
}
input#email {
  background-image: url('../assets/img/b.png');
  background-position: right 14px center;
}
input#password {
  background-image: url('../assets/img/password.svg');
  background-position: right 14px center;
}

.login-btn {
  background: #191919;
  border: 1px solid #787878 !important;
  color: #787878;
  font-weight: bold;
  font-family: 'Helvetica';
  width: 150px;
  height: 50px;
  text-transform: uppercase;
  margin-top:40px;
}

.text-info {
  line-height: 2rem;
  width: 100%;
  font-size: 18px;
  color: #c2c2c2 !important;
  font-family: 'AvenirNextPForBBG-Regular', 'Helvetica', 'Aria', 'sans-serif' !important;
}

@media screen and (max-width: 1263px) {
  .login-area {
    right: 0 !important;
    top: 0px !important;
    height: 100vh;
  }
}

@media screen and (max-width: 959px) {
  .login-area {
    height: 100vh;
    min-width: 100vw !important;
  }
}
</style>