<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-toolbar-title
      class="font-weight-light white--text"
      
    >
        <img src="../assets/logo.png" width="100" alt="Stankevicius">
    </v-toolbar-title>

    <div class="menu ml-5 text-uppercase" v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn active-class="orange white--text" class="text-uppercase mr-2" dark text to="/">
        <!-- <v-icon>mdi-view-quilt</v-icon>  -->
        Dashboard</v-btn>
      <v-btn active-class="orange white--text" class="text-uppercase mr-2" dark text to="/users">
      <!-- <v-icon>mdi-account</v-icon>  -->
      Users</v-btn>
      <v-btn active-class="orange white--text" class="text-uppercase mr-2" dark text to="/posts">
      <!-- <v-icon>mdi-file-document-outline</v-icon> -->
       TRADES</v-btn>
       <v-btn active-class="orange white--text" class="text-uppercase mr-2" dark text to="/reports">
      <!-- <v-icon>mdi-file-document-outline</v-icon> -->
       Reports</v-btn>
       <v-btn active-class="orange white--text" class="text-uppercase mr-2" dark text to="/connects">
      <!-- <v-icon>mdi-file-document-outline</v-icon> -->
       Connects</v-btn>

      <v-btn
        min-width="0"
        text
        dark
        @click="logout"
        class="text-uppercase"
      >
        Logout
      </v-btn>
    </div>

    <v-spacer />

    <v-btn fab v-if="$vuetify.breakpoint.smAndDown" @click="drawer = !drawer"><v-icon>mdi-menu</v-icon></v-btn>
    
    
    <v-navigation-drawer color="black" app temporary v-if="$vuetify.breakpoint.smAndDown" absolute v-model="drawer">
        <v-list
          dense
          nav
        >
          <v-list-item>
            <v-list-item-content>
              <img src="../assets/logo.png" width="100" alt="Stankevicius">
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider class="my-4"></v-divider>
        <v-list nav>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn text class="text-uppercase" @click="$router.push('/users')">
                  <!-- <v-icon class="pr-2">mdi-file-document</v-icon> -->
                   Users</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn text class="text-uppercase" @click="$router.push('/posts')">
                  <!-- <v-icon class="pr-2">mdi-account</v-icon> -->
                   TRADES</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn text class="text-uppercase" @click="$router.push('/reports')">
                  <!-- <v-icon class="pr-2">mdi-account</v-icon> -->
                   Reports</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn text class="text-uppercase" @click="$router.push('/connects')">
                  <!-- <v-icon class="pr-2">mdi-account</v-icon> -->
                   Connects</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn text class="text-uppercase" @click="logout">
                  <!-- <v-icon class="pr-2">mdi-power</v-icon>  -->
                  Logout</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

  </v-app-bar>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
            drawer: false,
        }
    },
    methods: {
        logout() {
            this.removeLoggedUser()
            this.$store.dispatch('signOutRequest')
            this.$router.push('/login')
        },
    },
}
</script>

<style lang="stylus" scope>
  
</style>